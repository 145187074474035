import api from '@/utils/api'

export default {
    getArticles(page, size) {
        const range = '?range=' + encodeURIComponent(`[${page - 1},${size}]`)
        return api.get('articles' + (page ? range : '') + `&sort=${encodeURIComponent('[date, DESC]')}`)
    },

    getArticle(id) {
        return api.get(`articles/${id}`)
    }
}
